<template>
  <q-layout view="hHh lpR fFf" style="position: fixed; top: 0px; left: 0px; width: 100%; height:100%; z-index: 100;">
    <!-- <q-dialog v-model="views" maximized> -->
      <q-drawer show-if-above v-model="left" side="left" bordered>
        <div class="row justify-between q-pa-md text-left text-light-blue-1 bg-light-blue-6 ">
          <div>
            <div>
              Dashboard
            </div>
            <div class="text-h5">
              SMP AFBS
            </div>
          </div>
          <div>
            <q-btn dense style="width: 60px;" class="text-red" label="logout" no-caps color="light-blue-2" @click="logout" />
          </div>
        </div>
        <q-list>
          <q-item
            clickable
            v-ripple
            :active="link === 'data-pendaftar'"
            @click="onClick('data-pendaftar')"
            active-class="data-pendaftar"
          >
            <q-item-section>Data Pendaftar</q-item-section>
          </q-item>

        </q-list>
        
      </q-drawer>
      <q-page-container class="bg-white full-width">
        <router-view />
      </q-page-container>

    <!-- </q-dialog> -->
  </q-layout>
</template>

<script>
export default {
  data () {
    return {
      left: true,
      link: 'data-pendaftar',
      confirm: false,
      views: true
    }
  },
  mounted(){
    if(!this.$store.state.currentUser){
      this.$router.push('/admin-login')
    }
    else if(this.$store.state.currentUser.role != 'admin'){
      this.$router.push('/')
      }
    else{
        this.$q.notify({
          color: "positive",
          message: "Anda login sebagai admin",
        });
    }

  },

  methods:{
    onClick(val){
      this.link = val
      this.$router.push({name: val}).catch(() => {});
    },
    logout(){
      localStorage.removeItem("smp-afbs");
      this.$router.push('/')
      this.$router.go();
    }
  }
}
</script>
<style lang="scss" scoped>

.my-menu-link{
  color: white;
  background-color: #048eff;
}
</style>